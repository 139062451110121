import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';

export const assessmentApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Assessment,
  tagTypes: ['Assessment'],
  endpoints: (builder) => ({
    getAssessments: builder.query({
      query: () => `${EndpointsUrl.Assessment}`,
      providesTags: ['Assessment'],
    }),
    getBaselineAssessment: builder.query<any, any>({
      query: ({ type, id }) => `${EndpointsUrl.Assessment}/${type}/${id}`,
      providesTags: ['Assessment'],
    }),
    updateAssessment: builder.mutation<any, any>({
      query: ({ type, data }) => ({
        url: `${EndpointsUrl.Assessment}/${type}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Assessment'],
    }),
  }),
});

export const { useLazyGetBaselineAssessmentQuery, useUpdateAssessmentMutation, useGetAssessmentsQuery } = assessmentApi;
export const assessmentApiUtil = assessmentApi.util;