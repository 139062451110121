/* eslint-disable import/no-cycle */
// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import { reducer as authSliceReducer } from './slices/auth';
import { reducer as coachSliceReducer } from './slices/coach';
import { reducer as clientSliceReducer } from './slices/client';

import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import { authApi } from './services/auth';
import { usersApi } from './services/users';
import { contentApi } from './services/content';
import { tier1Api } from './services/tier1';
import { coachApi } from './services/coach';
import { clientApi } from './services/clients';
import { groupApi } from './services/groups';
import { assessmentApi } from './services/assessment';
import { notesApi } from './services/notes';
import { homeworkApi } from './services/homework';
import { messagesApi } from './services/messages';
import { sessionApi } from './services/session';
import { appointmentApi } from './services/appointment';
import { dashboardApi } from './services/dashboard';
import { calendlyCalendarApi } from './services/calendlyCalendar';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  authSlice: authSliceReducer,
  snackbar: snackbarReducer,
  coachSlice:persistReducer(
    {
      key: 'coach',
      storage,
      keyPrefix: 'ht-',
    },
    coachSliceReducer,
  ),
  clientSlice:persistReducer(
    {
      key: 'client',
      storage,
      keyPrefix: 'ht-',
    },
    clientSliceReducer,
  ),
  user: persistReducer(
    {
      key: 'user',
      storage,
      keyPrefix: 'ht-',
    },
    userReducer,
  ),
  menu: menuReducer,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer, 
   [contentApi.reducerPath]:contentApi.reducer,
   [tier1Api.reducerPath]: tier1Api.reducer,
   [coachApi.reducerPath]: coachApi.reducer,
   [clientApi.reducerPath]: clientApi.reducer,
   [assessmentApi.reducerPath]: assessmentApi.reducer,
   [notesApi.reducerPath]: notesApi.reducer,
   [groupApi.reducerPath]: groupApi.reducer,
   [homeworkApi.reducerPath]:homeworkApi.reducer,
   [messagesApi.reducerPath]:messagesApi.reducer,
   [sessionApi.reducerPath]:sessionApi.reducer,
  [appointmentApi.reducerPath]: appointmentApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [calendlyCalendarApi.reducerPath]: calendlyCalendarApi.reducer,
});

export default reducer;
export type IRootReducer = typeof reducer;
export type IRootState = ReturnType<typeof reducer>;
