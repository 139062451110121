import { createApi } from '@reduxjs/toolkit/query/react';
import { Calendar, UpdateCalendlyData } from 'types/custom/calendly';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import Tags from './tagsEnums';

export const calendlyCalendarApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Calendly,
  tagTypes: [Tags.CALENDLY_CALENDAR],
  endpoints: (builder) => ({
    getCalendar: builder.query({
      query: () => `calendly/calendars`,
      providesTags: [Tags.CALENDLY_CALENDAR],
    }),
    updateCalendar: builder.mutation<Calendar[], UpdateCalendlyData>({
      query: (calendarData) => ({
        url: 'calendly/add-calendar',
        method: 'PATCH',
        body: calendarData,
      }),
      invalidatesTags: [Tags.CALENDLY_CALENDAR],
    }),
    logoutCalendly: builder.mutation({
      query: () => ({
        url: `calendly/logout`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.CALENDLY_CALENDAR],
    }),
  }),
});

export const { useLazyGetCalendarQuery, useUpdateCalendarMutation, useGetCalendarQuery, useLogoutCalendlyMutation } =
  calendlyCalendarApi;
export const calendlyCalendarApiUtil = calendlyCalendarApi.util;
